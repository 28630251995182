export default {
  language_picker_helper: "LAN",
  languages: {
    english: "English",
    german: "German",
    dutch: "Dutch",
    italian: "Italian",
  },
  language: "Language",
  languageForCorrespondence: "Language for Correspondence",
  // PatientRegister.vue
  linkExpiredText: "Registration Link expired! Please contact the clinic",
  selfRegister: "Self Register",
  confirmEmailText: "Please confirm your email address.",
  required: "Required.",
  invalidPassword: "The password must contain at least one digit.\n" +
      "The password must contain at least one lowercase letter.\n" +
      "The password must contain at least one uppercase letter.\n" +
      "The password must contain at least one special character from the provided set.\n" +
      "The length of the password must be between 8 and 32 characters.",
  passwordNotMatch: "Password do not match",
  usernameAlreadyExists: "Username already exists",
  invalidEmail: "Invalid email.",
  DSGVOTitle: 'Privacy Policy',
  DSGVOText: '<p>The following third-party provider provides the technical infrastructure for the MedITEX Online Survey and processes the data on our behalf:' +
      '          <br/></br>' +
      '          CRITEX GmbH</br>' +
      '          Stobäusplatz 4</br>' +
      '          93047 Regensburg</br>' +
      '          Germany' +
      '          <br/><br/>' +
      '          The MedITEX Online Survey was developed in accordance with the European General Data Protection Regulation (GDPR) and is continuously operated in compliance with it. Please note that the privacy policy of the responsible clinic has always been respected. ' +
      '          We are obliged under the EU General Data Protection Regulation to inform you about the processing of your personal data, in particular health data, in the context of using the online medical history form.' +
      '        </p>' +
      '        <h2>1. RESPONSIBILITY FOR DATA PROCESSING</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Responsible for data processing is:' +
      '          <br/>' +
      '          { ClinicName } hereinafter referred to as "Clinic".' +
      '          <br/>' +
      '          You can reach the responsible data protection officer(s) under the contact details on the homepage of the clinic.' +
      '        </p>' +
      '        <h2>2. PURPOSE AND LEGAL BASIS OF DATA PROCESSING</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Your personal data is collected via the online medical history form in order to fulfill the treatment contract concluded between you and your doctor and the associated obligations. For this purpose, the clinic requires your personal master data (such as name, age, place of residence) as well as your health data, which you can enter in this online medical history form.' +
      '          <br/>' +
      '          The collection of health data is a prerequisite for your treatment. If the necessary information is not provided, careful treatment on the part of your physician cannot take place. However, if you do not wish your personal data to be collected via the online medical history form, please contact your physician. The legal basis for processing your data is Article 9(2)(h) GDPR in conjunction with Section 22(1)(1)(b) BDSG.' +
      '        </p>' +
      '        <h2>3. RECIPIENTS OF YOUR DATA</h2>' +
      '        <p>' +
      '          <br/>' +
      '          CRITEX GmbH (Stobäusplatz 4, 93051 Regensburg, info@critex.de) is an order processor of the clinic. In the course of this, CRITEX GmbH is responsible for providing this online medical history form. The content of the medical history form and the processing of the personal data is solely the responsibility of and determined by the clinic. Thus, the clinic is also the recipient of your data.' +
      '          <br/>' +
      '          CRITEX GmbH has concluded an order processing agreement with the clinic in order to protect your personal data.' +
      '          <br/>' +
      '          Within CRITEX GmbH, only those persons and offices will have access to your personal data that need it to fulfill our legal and contractual obligations/duties.' +
      '        </p>' +
      '        <h2>4. STORAGE OF YOUR DATA</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Your personal data will only be stored as long as it is necessary for the clinic to carry out the above-mentioned purposes. After the termination of the treatment contract between you and the clinic, your personal data will be stored as long as the clinic is legally obliged to do so. Due to legal requirements, the clinic is obligated to keep corresponding data for at least 10 years after completion of the treatment. According to other regulations, longer retention periods may apply, e.g. 30 years for X-ray records according to Section 28 (3) of the X-ray Ordinance.' +
      '        </p>' +
      '        <h2>5. YOUR RIGHTS</h2>' +
      '        <p>' +
      '          <br/>' +
      '          You have the right to obtain information about the personal data concerning you. You may also request that incorrect data be corrected.' +
      '          <br/>' +
      '          In addition, under certain conditions, you have the right to delete data, the right to restrict data processing, and the right to data portability.' +
      '          <br/>' +
      '          The processing of your data is based on legal regulations. Only in exceptional cases do we require your consent. In these cases, you have the right to revoke your consent for future processing.' +
      '          <br/>' +
      '          Furthermore, you have the right to complain to the competent supervisory authority for data protection if you believe that the processing of your personal data is not in compliance with the GDPR.' +
      '          <br/>' +
      '          The competent supervisory authority in data protection matters is the state data protection commissioner of the federal state in which our company is based. A list of data protection officers and their contact details can be found at the following link: https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html' +
      '        </p>' +
      '        <h2>6. OTHER</h2>' +
      '        <p>' +
      '          <br/>' +
      '          Further data protection regulations result from the contracts you have concluded with the clinic.' +
      '          <br/>' +
      '          If you have any questions or wish to exercise the aforementioned rights, please feel free to contact the clinic team.' +
      '        </p>' +
      '        <p>' +
      '          <br/>' +
      '          By clicking on the "Agree" button, you expressly agree to the processing of your personal data and the transmission of this data to the clinic.' +
      '        </p>',

  userName : "Username",
  lastName : "Lastname",
  firstName : "Firstname",
  birthdate: "Birthdate",
  password: "Password",
  repeatPassword: "Repeat password",
  register: "register",
  agb: "AGB",
  dsgvo_1: 'I have read the ',
  dsgvo_2: 'privacy policy',
  dsgvo_3: '.',
  close: "Close",
  dsgvoReminder: 'Please accept our terms of privacy to continue.',
  registrationConfirmation: 'Registration Confirmation',
  registrationConfirmed: 'Registration confirmed.',
  registrationConfirmationExpired: 'Registration confirmation link expired.',
  login: 'Log-In',
  email: 'Email',
  cancel: 'Cancel',
  create: 'Create',
  agree: 'Agree',
  disagree: 'Disagree',

  patients: 'Patients',
  openSurveys: 'Open surveys',
  chooseSurvey: 'Please choose a survey',
  noDataAvailable: 'No surveys could be found. Please note that a survey can only be completed once.',
  deletePartner: 'Delete partner',
  disconnectPartner: 'Disconnect partner',
  addPartner: 'Add partner',
  name: 'Name',
  forename: 'Forename',
  surveyName: 'Survey Name',
  status: 'Status',
  actions: 'Actions',
  deletePartnerTitle: 'Delete Partner',
  deletePartnerText: 'Do your really want to delete your partner?',
  disconnectPartnerTitle: 'Disconnect Partner',
  disconnectPartnerText: 'Do you really want to disconnect your partner? This is not reversible!',
  surveySentToClinic: 'Survey sent to clinic',
  surveySentBackToEdit: 'Survey sent back to edit',
  save: 'Save',
  sendSurvey: 'Send survey to clinic',
  surveys: 'Surveys',
  deleteSurveyTitle: 'Delete Survey',
  deleteSurveyText: 'Do you really want to delete this survey? This is not reversible!',
  savedCurrentState: 'Save current state',
  greeting: 'Hello',
  logout: 'Logout',
  addSurvey: "Add survey",
  help: "Help",
  helpText: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
  patientTutorial:'<div><ol type="1"><li>Click on <strong>Personal Data</strong> to fill in your contact and demographic information. If your clinic does not use the personal data survey, please skip to the <strong>Medical History</strong> section.</li>' +
      '<li>Please click on <strong>+ADD SURVEY</strong>, then choose the Survey you want to fill out.</li>\r' +
      '<li>If you want to complete the survey at a later time, click on the <strong>SAVE</strong> button, which is located on the right side.</li>' +
      '<li>Once you have completed the survey, please click on the <strong>SEND SURVEY TO CLINIC</strong> button on the right side.</li>' +
      '<li>Your partner can be added via the Personal Data page. Please click on <strong>+PARTNER</strong> below your own name and they can complete the same process.</li></ol><br>' +
      '<p>Medical History</p> \r' +
      '<ol type="1"><li>Click on <strong>Medical History</strong> to fill in your relevant medical history.</li>' +
      '<li>Please click on <strong>+ADD SURVEY</strong>, then choose the survey you want to fill out.</li>' +
      '<li>If you want to complete the survey at a later time, click on the <strong>SAVE</strong> button, which is located on the right side.</li>' +
      '<li>Once you have completed the survey, please click on the <strong>SEND SURVEY TO CLINIC</strong> button on the right side.</li>' +
      '<li>Your partner can be added via the Personal Data page, if not already done. Please click on <strong>+PARTNER</strong> below your own name and they can complete the same process.</li></ol></div>',
  imprint: 'imprint',
  typeMessage: "Type in a message",
  patientConversation:"Patient conversation",
  resendMail: "Resend mail",

  noResultsFound: "no results found",
  surveysPerSite: "Surveys Per Site",

  //patientProfile
  myAccount: "My Account",
  editAccount: "Edit Account",
  myPartner: "My Partner",
  editPartner: "Edit Partner",
  changePassword: "Change Password",
  currentPassword: "current password",
  newPassword: "new password",
  repeatNewPassword: "repeat new password",

  //decisionTree
  decisionTreeStartText: 'Please fill out this survey to the best of your conscience. If we can offer the service you are looking for, there is an option at the end to request an appointment.',
  phoneNumber: 'Phone number',
  appointmentRequest: 'Appointment Request',
  requestAppointment: 'request appointment',
  back: 'back',
  next: 'next',
  start: 'start',
  surveyNext: "next",
  surveyPrev: "previous",
  phoneNumberRule: "Invalid phone number! Only use 0-9 and -",
  nothingSelectedError: "Nothing selected!",
  appointmentRequestedText: "Thank you for entering your contact information. You should also receive a confirmation email. We will contact you shortly.",

  //passwordReset
  forgotPassword: "Forgot password?",
  forgotPasswordText: "Enter your username. A link will be send to you to reset your password.",
  sentResetMail: "A mail was sent to you. Click the link in the mail to reset your password",
  sendEmail: "Send Mail",
  backToLogin: "Back to login",
  resetPasswordText: "Please enter a new password",
  linkExpired: "This link is expired",
  resetPassword: "reset password",

  //snackbar
  unexpectedError: 'An unexpected error occurred',
  errorCreatingPartner: "Could not create partner",
  errorConfirmingRegistration: "Could not confirm registration",
  invalidLogin: "Wrong username or password!",
  errorDeletingPartner: "Could not delete partner",
  errorDisconnectingPartner: "Could not disconnect partner",
  errorFinishingRegistration: "Could not finish registration",
  errorRegisteringPatient: "Could not register patient",
  mailResent: "Resent mail",
  errorResendingMail: "Could not resend mail",
  errorDeletingSurvey: "Could not delete survey",
  surveyResultSaved: "Survey saved",
  errorSavingSurveyResult: "Could not save survey",
  surveyResultSendToClinic: "Survey result sent to clinic",
  errorGettingPatientInformation: "Could not get patient information",
  patientUpdated: "Patient updated",
  errorUpdatingPatient: "Could not update patient",
  partnerUpdated: "Partner updated",
  errorUpdatingPartner: "Could not update partner",
  passwordChanged: "Password changed",
  errorChangingPassword: "Could not change password",
  errorSendingResetMail: "Could not send mail",
  errorResettingPassword: "Could not reset password",
  messageDeleted: "Deleted message",

  //cookies
  cookiesText: "<div><p>The cookies stored on this website are only for the login process. Consent is <strong>required</strong> for use.</p></div>",
  cookieLabel: "Login",
  result: "Result",
  openDemographics: "Open demographics",
  demographicsNotAvailable: "A demographics survey is not yet available for your clinic.",
  information: "Information",
  invalidEMail: "Invalid e-mail.",
  gender: "Sex",
  female: "female",
  male: "male",
  diverse: "diverse",
  dateOfCreation: "Date of Creation",
  surveyInProgress: "In progress",
  notPossible: "Not possible",
  fillOut: "Save + fill out data",
  warningDemographicsFirst: "Please fill out and send the demographics survey to the clinic first.",
  leaveWithoutSaving: "Leaving this page will discard any unsaved changes. Are you sure you want to proceed?",
  personalData:"Personal Data",
  medicalHistory: "Medical History",
  surveyNotActivated: "Medical history feature is not active."
};

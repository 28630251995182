import Vue from 'vue'
import App from './App.vue'
import VueMq from 'vue-mq'
import { sync } from 'vuex-router-sync';
import store from './store';
import Vuetify from 'vuetify';
import router from './router';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css'
import { i18n } from './languages/i18n.js';
import { slk } from "survey-core";
Vue.config.productionTip = false

slk(
    "YjcxYTU2YWYtN2MwMi00ODQ1LTg4OTQtNGY3ZjljZmVlMjVhOzE9MjAyNS0wNC0xOCwyPTIwMjUtMDQtMTg="
);


Vue.use(Vuetify);
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mobile: 960, // = vue "sm"
    tablet: 1281,
    desktop: Infinity,
  },
  defaultBreakpoint: 'md' // customize this for SSR
})
sync(store, router);

new Vue({
  vuetify: new Vuetify(),
  i18n,
  components: { App },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
